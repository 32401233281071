import React from 'react'
import './style.css'
export default class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (this.props.show && this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.props.onChange();
        }
    }

    render() {
        return (
            <div className={this.props.show ? "menu-hna show": "menu-hna"} ref={this.wrapperRef}>
                <div className="menu-scroll">
                    <ul className="sidebar-top-level-items">
                        <li><a 
                            href      = "#about" 
                            className = {this.props.active == 'about' ? 'active' : ''}
                            onClick   = {() => this.props.onChange()}
                        >VỀ TÔI</a></li>

                        <li><a 
                            href      = "#photo" 
                            className = {this.props.active == 'photo' ? 'active' : ''}
                            onClick   = {() => this.props.onChange()}
                        >HÌNH ẢNH</a></li>

                        <li><a 
                            href      = "#audio" 
                            className = {this.props.active == 'audio' ? 'active' : ''}
                            onClick   = {() => this.props.onChange()}
                        >ÂM NHẠC</a></li>
                    </ul>
                </div>
            </div>
        )
    }
}