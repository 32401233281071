import React from 'react'
import './style.css'

export default class Carousel extends React.Component
{
    constructor(props) {
        super(props)
        this.state = {
            num : 2,
            active: 1,
            active_old : 0,
        }
    }
    renderVideo (index) {
        if(this.props.videos[index]) {
            var col = "col-" + (12 / this.state.num);
            return (
                <div key={index} className={col}>
                    <img src={this.props.videos[index].src} />
                </div>
            )
        }
        return null;
    }

    renderNumVideo (index) {
        var videos=[];
        for(var i=0;i<this.state.num;i++) {
            videos.push(this.renderVideo(index + i));
        }
        return videos
    }

    classNameCarousel(index) {
        if(this.state.active > this.state.active_old) {
            if(this.state.active == (index)) {
                return "item-carousel-wi active rightin" ;
            } else if(this.state.active_old == (index)) {
                return "item-carousel-wi active rightout";
            } else {
                return "item-carousel-wi";
            }
        } else {
            if(this.state.active == (index)) {
                return "item-carousel-wi active leftin" ;
            } else if(this.state.active_old == (index)) {
                return "item-carousel-wi active leftout";
            } else {
                return "item-carousel-wi";
            }
        }
    }

    renderRowVideo() {
        if(this.props.videos) {
            var videos = [];
            for(var i=0; i < (Math.floor((this.props.videos.length +1 )/this.state.num)); i++) {
                videos.push(
                    <div key={i+1} className={this.classNameCarousel(i+1)}
                        >
                        <div className="row">
                            {this.renderNumVideo(i*this.state.num)}
                        </div>
                    </div>
                );
            }
            return videos.map((item) => {
                return item;
            });
        }
        return null;
    }

    renderLi() {
        if(this.props.videos) {
            var li_list = [];
            for(var i = 0; i < (Math.floor((this.props.videos.length +1 )/this.state.num)); i++ ) {
                const j = i+1;
                li_list.push(
                    <li 
                        key={i}
                        className={this.state.active == j ? "active" : ""} 
                        onClick={() => {
                            var active = this.state.active;
                            if(j != active) {
                                this.setState({
                                    active : j,
                                    active_old : active
                                })
                            }
                        }}
                    > </li>
                );
            }
            return li_list.map((item) => {
                return item;
            });
        }

        return null;
    }

    renderIndicators() {
        return (
            <div className="row carousel-ol">
                <div className="col-12 carousel-ol">
                    <ol>
                        {this.renderLi()}
                    </ol>
                </div>
            </div>
        )
    }

    render () {
        return (
            <div id="carousel-wiooh-oi">
                <div id="carousel-wiooh">
                    {this.renderRowVideo()}
                </div>
                {this.renderIndicators()}
            </div>
        )
    }
}