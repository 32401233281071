import React from 'react'
import './style.css'

export default class Footer extends React.Component {
    render() {
        return (
            <div className="container-fluid text-center" id="footer">
                <h5>Designed by <a href="https://wiooh.com/"  alt="wiooh website">WiOOH</a></h5>
            </div>
        )
    }
}