import React from 'react'
import Menu from '../../components/menu-top'
import About from './about'
import Image from './image'
import Audio from './audio'
import Footer from './footer'

export default class Home extends React.Component
{
    render() {
        return (
            <div className="ontainer-fluid">
                <Menu />
                <About />
                <Image />
                <Audio />
                <Footer />
            </div>
        )
    }
}