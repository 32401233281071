import React from 'react'
import './style.css'
import Menu from './menu'
import Navbar from './navbar'

export default class MenuTop extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            active : '',
            show : false,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (event) => {
        var scrollY = window.scrollY;
        var height = 80;
        var about = document.getElementById("about") ? document.getElementById("about").getBoundingClientRect().height : 0;
        var photo = document.getElementById("photo") ? document.getElementById("photo").getBoundingClientRect().height : 0;
        var audio = document.getElementById("audio") ? document.getElementById("audio").getBoundingClientRect().height : 0;
        var active = "about";
        if(scrollY < height + about) {
            active = "about";
        } else if( scrollY < height + about + photo) {
            active = "photo";
        } else if( scrollY < height + about + photo + audio) {
            active = "audio";
        } else {
            active = "audio";
        }
        if(active != this.state.active) {
            this.setState({active : active});
        }
    }

    render () {
        return (
            <div>
                <Navbar 
                    active={this.state.active}
                    show={this.state.show}
                    onShow={() => {this.setState({ show : true })} }/>
                <Menu 
                    active={this.state.active}
                    show={this.state.show}
                    onChange={() =>this.setState({ show : false })}
                />
            </div>
        )
    }
}