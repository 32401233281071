import React from 'react'
import './style.css'
import ModelLibrary from '../../../components/image-library'

export default class Image extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {
            isOpen       : false,
            currentImage : 0,
            images       : [
                { src : "/image/image3.jpg" },
                { src : "/image/image5.jpg" },
                { src : "/image/image4.jpg" },
            ]
        }
    }
    
    openModel () {
        if(this.state.isOpen) {
            return <ModelLibrary 
                images       = {this.state.images}
                isOpen       = {true}
                currentImage = {this.state.currentImage}
                closed       = {() => {this.setState({isOpen:false})}}
                
            />
        }
        return  null;
    }

    setOpen = (currentImage) => {
        this.setState({ 
            isOpen : true ,
            currentImage : currentImage
        });
    }

    render () {
        return (
            <div className="container-fluid" id="image-mymy">
                <div className="container" id="photo">
                    <div className="row">
                        <div className="col-3 heade-mymy" >
                            <span className="line-red"></span>
                            <h1>Hình ảnh</h1>
                        </div>
                        <div className="col-9">
                            <h5>
                                Với nhiều nốt thăng trầm trong sự nghiệp, nhưng chưa bao giờ tôi có ý định từ bỏ, chưa bao giờ cạn tình yêu và niềm tin với âm nhạc. Âm nhạc luôn là tri kỉ với tôi.
                            </h5>
                        </div>
                        <div className="col-4">
                            <img src="/image/image3.jpg" alt="my my singer" onClick={() => this.setOpen(0)}/>
                            <img src="/image/image5.jpg" alt="my my singer" onClick={() => this.setOpen(1)}/>
                        </div>
                        <div className="col-8">
                            <img src="/image/image4.jpg" alt="my my singer" onClick={() => this.setOpen(2)}/>
                            <p>
                                Music give a soul to the universe, wings to the mind,
                                flight to the imagination and life to everything.
                            </p>
                            <a herf="#" >Xem tất cả hình ảnh >>></a>
                        </div>
                    </div>
                </div>
                {this.openModel()}
            </div>
        )
    }
}