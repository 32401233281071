import React from 'react'
import './style.css'

export default class About extends React.Component
{
    render() {
        return (
            <div className="container-fluid" id="about-mymy">
                <div className="container block-1" id="about">
                    <img src="/image/image_1.png" alt="My My avatar" />
                </div>
                <div className="container-fluid block-2"> 
                    <div className="container">
                        <div className="content">
                            <h5>Xin chào,</h5><br/>
                            <h3>Tôi là Mỹ Mỹ!</h3><br/>
                            <span className="border-box-mymy"></span>
                            <img src="/image/svg/rg1.svg" className="img-bgimage" alt="my my singer"/>
                        </div>
                    </div>
                    <div className="bgmymy-about">
                        <div className="img-about">
                            <img src="/image/mymyabout2.png" className="img-bg2" alt="my my background singer" />
                        </div>
                    </div>
                    <div className="container">
                        <div className="content set-width">
                            <span className="box-content set-width">
                                <span>
                                    <p>"Âm nhạc thể hiện những điều không thể nói nhưng cũng không thể lặng câm.” Victor Hugo </p>
                                    <p>Với nhiều nốt thăng trầm trong sự nghiệp, nhưng chưa bao giờ tôi có ý định từ bỏ, 
                                        chưa bao giờ cạn tình yêu và niềm tin với âm nhạc. Âm nhạc luôn là tri kỉ với tôi.
</p>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}