import React from 'react'
import './style.css'


export default class navbar extends React.Component {
    render() {
        return (
            <div>
                <div className="navbar-hna">
                    <div className="container">
                        <div className="navbar-button" onClick={ () => this.props.onShow()} disabled={this.props.show}>
                            <a className="icon" >
                                <i className="fa fa-bars"></i>
                            </a>
                        </div>
                        <div className="navbar-icon-chuky">
                            <img src="/image/svg/chuky3.svg" alt="My My singer icon" />
                        </div>
                        <div className="navbar-menu">
                            <ul>
                                <li><a href="#about" className={this.props.active == 'about' ? 'active' : ''}>VỀ TÔI</a></li>
                                <li><a href="#photo" className={this.props.active == 'photo' ? 'active' : ''}>HÌNH ẢNH</a></li>
                                <li><a href="#audio" className={this.props.active == 'audio' ? 'active' : ''}>ÂM NHẠC</a></li>
                            </ul>
                        </div>
                        <div className="navbar-icon">
                            <img src="/image/svg/facebook.svg" alt="icon facebook"/>
                            <img src="/image/svg/socialmedia.svg" alt="icon1"/>
                            <img src="/image/svg/youtube.svg" alt="icon2"/>
                            <img src="/image/svg/whatapp.svg" alt="icon3"/>
                        </div>
                    </div>
                </div>
                <div className="margin-80"></div>
            </div>
        )
    }
}