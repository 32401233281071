import React from 'react'
import './style.css'
import Carousel from '../../../components/carousel'

export default class Audio extends React.Component
{
    render () {
        return (
            <div className="container-fluid" id="audio-mymy">
                <img src="/image/svg/bg_chuky.svg" className="img-bg-audio"></img>
                <div className="container" id="audio">
                    <div className="row">
                        <div className="col-3 heade-mymy" >
                            <span className="line-red"></span>
                            <h1>Âm nhạc</h1>
                        </div>
                        <div className="col-9">
                            <h5>
                               Âm nhạc là cách cảm xúc trở thành âm thanh. Vì thế hãy cùng lắng nghe và cảm nhận hạnh phúc cùng Mỹ Mỹ bạn nhé!!!

                            </h5>
                        </div>
                    </div>
                </div>
                <div className="container row2">
                    <Carousel 
                        videos={[
                            {src : '/image/image6.png'},
                            {src : '/image/image7.jpg'},
                            {src : '/image/image6.png'},
                            {src : '/image/image7.jpg'},
                            {src : '/image/image6.png'},
                            {src : '/image/image7.jpg'},
                            {src : '/image/image6.png'},
                            {src : '/image/image7.jpg'},
                            {src : '/image/image6.png'},
                            {src : '/image/image7.jpg'},
                        ]}
                    />
                    <div className="col-12 text-right link">
                        <a href="#video"> Xem tất cả video >>></a>
                    </div>
                </div>
            </div>
        )
    }
}